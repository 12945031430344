<template>
    <div class="contact">
        <h1>Contact Me</h1>
        <div v-if="submitted">
            <h3>Thank you for your email, I will be in touch :)</h3>
        </div>
        <div v-else class="form" :class="{'label-left': displayLabelLeft}">
            <div class="form-group name">
                <label v-if="labels">
                    Name:
                </label>
                <input v-model="form.name" name="name" :placeholder="this.labels ? 'John Doe' : 'Name'" type="text">
            </div>
            <div class="form-group email">
                <label v-if="labels">
                    Email:
                </label>
                <input v-model="form.email" name="email" :placeholder="this.labels ? 'john@example.com' : 'Email Address'" type="email">
            </div>
            <div class="form-group phone">
                <label v-if="labels">
                    Phone:
                </label>
                <input v-model="form.phone" name="phone" :placeholder="this.labels ? '1234567890' : 'Phone Number'" type="text">
            </div>
            <div class="form-group message">
                <label v-if="labels">
                    Message:
                </label>
                <textarea v-model="form.message" name="message" :placeholder="this.labels ? 'Type message here...' : 'Message'"></textarea>
            </div>
            <div class="button-group">
                <button v-if="loading" @click.prevent>Submit <font-awesome-icon icon="spinner" class="fa-spin disabled"></font-awesome-icon></button>
                <button v-else @click.prevent="submit">Submit</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data () {
        return {
            form: {
                name: null,
                email: null,
                phone: null,
                message: null
            },
            submitted: false,
            loading: false,
            labels: true,
            displayLabelLeft: false
        }
    },
    metaInfo: {
        title: 'Contact Me',
        titleTemplate: '%s | Kodie Upton',
        meta: [
            { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            {
                name: 'description',
                content: 'Feel free to contact me :)'
            }
        ]
    },
    methods: {
        submit: function () {
            this.loading = true
            const formData = this.form
            axios.post('https://formspree.io/xjvojaqk', formData).then((response) => {
                this.loading = false
                this.submitted = true
            }).catch((error) => {
                console.error(error)
            })
        }
    }
}
</script>
